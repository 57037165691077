







































































































































import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import { contactsStore, publicGroupsStore, uiStore, integrationsStore, teamsStore } from '@/store'
import { defaultLogger } from '@/loggers'

type TabName =
  | 'new'
  | 'connected'

interface Tab {
  name: TabName;
  title: string;
}

@Component({
  components: {
    BaseSpinner,
    IntegrationsTabNew: () => import('./IntegrationsTabNew.vue'),
    IntegrationsTabConnected: () => import('./IntegrationsTabConnected/index.vue'),
  },
})
export default class Integrations extends Vue {
  isLoading = true
  currentTab: TabName = 'new'
  errorTitle: string | null = null
  errorDetails: string | null = null
  errorCounter = 0
  errorCounterThreshold = 1

  @State(state => state.contacts.data) contacts!: Record<string, TADA.Contact>
  // @Getter currentModalPayload!: { tab: TabName }

  // TODO: remove any, set type for payload
  get currentModalPayload (): any {
    return uiStore.getters.currentModalPayload
  }

  get hasConnectedIntegrations (): boolean {
    return integrationsStore.state.data?.length > 0
  }

  get hasError (): boolean {
    return this.errorTitle !== null || this.errorDetails !== null
  }

  get showSupportBtn (): boolean {
    return this.errorCounter > this.errorCounterThreshold
  }

  get tabs (): Tab[] {
    return [
      {
        name: 'connected',
        title: this.$t('modals.Integrations.connected').toString(),
      },
      {
        name: 'new',
        title: this.$t('modals.Integrations.servicesList').toString(),
      },
    ]
  }

  created () {
    this.fetch()
  }

  activated () {
    this.changeTabIfRequired()
  }

  /**
   * check if there is a tab to switch to in the modal payload
   * used for a case when creating a new integration from 'new' tab
   * where it makes sense to return back to 'connected' tabs
   */
  changeTabIfRequired (): void {
    const tab = this.currentModalPayload?.tab
    tab && (this.currentTab = tab)
  }

  close (): void {
    uiStore.actions.hideModal()
  }

  contactSupport (): void {
    const chatId = contactsStore.getters.supportChatId
    this.$router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: chatId,
      },
    })
    this.close()
  }

  async fetch (): Promise<void> {
    this.isLoading = true
    this.errorTitle = null
    this.errorDetails = null

    try {
      await Promise.all([
        integrationsStore.actions.setup(),
        publicGroupsStore.actions.loadAll(),
      ])
    } catch (e) {
      defaultLogger.warn('e', e)
      this.errorCounter += 1
      this.errorTitle = e.error || null
      if (e.details) {
        this.errorDetails = Object.values(e.details).join(' ')
      }
    }

    this.hasConnectedIntegrations && (this.currentTab = 'connected')
    this.isLoading = false
  }
}
